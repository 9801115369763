import { createStore } from 'redux';

import reduxTypes from './actionTypes';

const getInitialState = () => ({
    csrf: null,
    currentPage: null
});

const updatePageInformation = (page) => {
    switch (page) {
        case 'Homepage':
            document.title = 'URLi - URL Shortener';
            break;
        default:
            document.title = `URLi - ${page.split(/(?=[A-Z])/).join(' ')}`;
            break;
    }
};

const reducer = (state = getInitialState(), {type, data}) => {
    switch (type) {
        case reduxTypes.SET_VALUE: {
            const [key, value] = Object.entries(data)[0];
            if (key === 'currentPage') updatePageInformation(value);
            return { ...state, [key]: value };
        }
        default:
            return state;
    }
};

const store = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
