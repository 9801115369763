import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './firebase/firebase';
import * as serviceWorker from './serviceWorker';
import store from './store/store';

import SiteRouter from './components/SiteRouter';

ReactDOM.render(
    <Provider store={store}>
        <SiteRouter />
    </Provider>,
    document.getElementById('root'),
    () => {
        // Check domains and point to main one
        const {location} = window;
        const hosts = [
            'urli-shortener.firebaseapp.com',
            'urli-shortener.web.app'
        ];

        if (hosts.includes(location.host)) {
            window.location = `https://urli.io${(location.pathname.length) ? `/${location.pathname}` : ''}`;
        }
    }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
