import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyBSdEKic31MkmUnJRf1Ed2cpK0aa1qQjrM",
    authDomain: "urli-shortener.firebaseapp.com",
    databaseURL: "https://urli-shortener.firebaseio.com",
    projectId: "urli-shortener",
    storageBucket: "urli-shortener.appspot.com",
    messagingSenderId: "990594629108",
    appId: "1:990594629108:web:44d609f78b61c93698cda1",
    measurementId: "G-RE5HGBTRZS"
};

// Initialize app
firebase.initializeApp(firebaseConfig);

// Enable Firebase Analytics
if (process.env.NODE_ENV !== 'development') {
    firebase.analytics();
}

export default firebase;
