import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LayoutManager from './LayoutManager/LayoutManager';

const SiteRouter = () => {
    return (
        <Router basename="/">
            <Switch>
                <Route exact path="/" render={(props) => (
                    <LayoutManager {...props} layout="DefaultLayout" page="Homepage" />
                )} />
                <Route path="/terms-of-service" render={(props) => (
                    <LayoutManager {...props} layout="DefaultLayout" page="TermsOfService" />
                )} />
                <Route exact path="/privacy-policy" render={(props) => (
                    <LayoutManager {...props} layout="DefaultLayout" page="PrivacyPolicy" />
                )} />
                <Route path="*" render={(props) => (
                    <LayoutManager {...props} layout="DefaultLayout" page="Server404" />
                )} />
            </Switch>
        </Router>
    );
};

export default SiteRouter;
