// import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { blueGrey, orange, red } from '@material-ui/core/colors';

const userColorPrefDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

const theme = responsiveFontSizes(createMuiTheme({
    overrides: {
        // MuiAppBar: {
        //     colorDefault: {
        //         backgroundColor: blueGrey[900]
        //     }
        // }
    },
    palette: {
        type: (userColorPrefDark && userColorPrefDark.matches) ? 'dark' : 'light',
        primary: {
            main: blueGrey[500],
        },
        secondary: {
            main: orange[800]
        },
        error: {
            main: red[800]
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'Arial',
            'sans-serif'
        ].join(','),
    },
}));

export default theme;
