import React from 'react';
import { connect } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import reduxActions from "../../store/actions"
import DefaultTheme from './Themes/DefaultTheme';

class LayoutManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: null
        };
    }

    componentDidMount() {
        if (this.props.page) {
            this.setCurrentPage(this.props.page);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.currentPage !== nextProps.page && nextState.currentPage !== nextProps.page) {
            this.setCurrentPage(nextProps.page);
            return true;
        }

        return false;
    }

    setCurrentPage(page) {
        this.setState({currentPage: page}, () => {
            this.props.storeDispatch.setValue({ currentPage: page});
        });
    }

    getPage(page) {
        const RenderPage = React.lazy(() => import(`./../../pages/${page}/${page}`));

        return (
            <React.Suspense fallback={<></>}>
                <RenderPage {...this.props} />
            </React.Suspense>
        );
    }

    getLayout(layout) {
        const RenderLayout = React.lazy(() => import(`./Layouts/${layout}`));

        return (
            <React.Suspense fallback={<></>}>
                <ThemeProvider theme={DefaultTheme}>
                    <CssBaseline />
                    <RenderLayout {...this.props} getPage={this.getPage.bind(this)} />
                </ThemeProvider>
            </React.Suspense>
        );
    }

    render() {
        return this.getLayout(this.props.layout);
    }
}

const mapDispatchToProps = (dispatch) => ({
    storeDispatch: {
        setValue: (value) => {
            dispatch(reduxActions.setValue(value));
        }
    }
});

export default connect(null, mapDispatchToProps)(LayoutManager);
